import * as React from "react";
import { graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleDown } from "@fortawesome/pro-light-svg-icons";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import EventiSlider from "../../components/EventiSlider/EventiSlider";

export default function GratuitiProfessionePage({ data }) {
  const {
    professioniJson: professione,
    allEventiFad: { nodes: eventiFad },
    allEventiRes: { nodes: eventiRes },
    allEventiFsc: { nodes: eventiFsc },
    totaleEventi: { totalCount },
  } = data;

  return (
    <Layout pageTitle={`Corsi ECM gratuiti per ${professione.descrizione}`}>
      <section className="position-relative">
        <StaticImage
          src="../../images/header-provider.jpg"
          className="img-header-home"
          alt={`Professione ${professione.descrizione}`}
          title={`Professione ${professione.descrizione}`}
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1 className="text-center text-white display-4 font-weight-bold">
              CORSI ECM gratuiti per {professione.descrizione}
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Scegli tra i {totalCount} corsi ECM gratuiti per{" "}
              {professione.descrizione} disponibili.
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri i corsi
            </a>
          </div>
        </div>
      </section>
      <section id="sezione-corsi" className="container-fluid p-5">
        <h2 className="my-4 text-center titolo-sezione">
          Corsi ECM FAD in evidenza
        </h2>
        <EventiSlider eventi={eventiFad} />
      </section>
      <section className="container-fluid p-5">
        <h2 className="my-4 text-center titolo-sezione">
          Corsi ECM RES in evidenza
        </h2>
        <EventiSlider eventi={eventiRes} />
      </section>
      <section className="container-fluid p-5">
        <h2 className="my-4 text-center titolo-sezione">
          Corsi ECM FSC in evidenza
        </h2>
        <EventiSlider eventi={eventiFsc} />
      </section>
    </Layout>
  );
}

export const query = graphql`
  query ($descrizione: String = "") {
    professioniJson(descrizione: { eq: $descrizione }) {
      id
      descrizione
    }
    totaleEventi: allEventiJson(
      filter: {
        professioni: { elemMatch: { professione: { eq: $descrizione } } }
        quota_partecipazione: { eq: "0,00 €" }
      }
    ) {
      totalCount
    }
    allEventiFad: allEventiJson(
      limit: 11
      sort: { fields: data_fine_timestamp, order: DESC }
      filter: {
        tipo_evento: { eq: "FAD" }
        professioni: { elemMatch: { professione: { eq: $descrizione } } }
        quota_partecipazione: { eq: "0,00 €" }
      }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
    allEventiRes: allEventiJson(
      limit: 11
      sort: { fields: data_fine_timestamp, order: DESC }
      filter: {
        tipo_evento: { eq: "RES" }
        professioni: { elemMatch: { professione: { eq: $descrizione } } }
        quota_partecipazione: { eq: "0,00 €" }
      }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
    allEventiFsc: allEventiJson(
      limit: 11
      sort: { fields: data_fine_timestamp, order: DESC }
      filter: {
        tipo_evento: { eq: "FSC" }
        professioni: { elemMatch: { professione: { eq: $descrizione } } }
        quota_partecipazione: { eq: "0,00 €" }
      }
    ) {
      nodes {
        data_inizio
        data_fine
        crediti
        id_evento
        id
        numero_edizione
        ragione_sociale
        provider_id
        titolo_evento
        tipo_evento
        slug
        professioni {
          disciplina
          professione
        }
      }
    }
  }
`;
